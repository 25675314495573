import { ESAddressDetail } from 'common/types/Address'
import { Valuation } from 'common/types/Valuation.type'
import { mapAddressDetails } from 'modules/AddressDetails/utils/mapAddressDetails'

export const isAccountCreated = async ({
  postalCode, area, floor, unit
}: {
  postalCode:string
   area:string
   floor:string
   unit:string
}) => {
  const payload = {
    postal: postalCode || ''
  }
  const searchAddressParams = new URLSearchParams(payload).toString()
  const addressResult:ESAddressDetail[] = await fetch(`/api/search/addressDetails/?${searchAddressParams}`).then(
    (res) => res.json()
  )
  const filteredAddress = addressResult[0]
  if (!filteredAddress) {
    return false
  }
  const propertyType = filteredAddress.propertyType
  const payloadForValuation = {
    postalCode,
    floor,
    area,
    flatType: propertyType,
    propertyType: 'Condo'
  }

  const valParams = new URLSearchParams(payloadForValuation).toString()
  const ValuationRes: Valuation = await fetch(
      `/api/property/getValuation/?${valParams}`
  ).then((res) => res.json())

  if (ValuationRes.valuation === null) {
    return false
  }

  const addressPayload = mapAddressDetails(
    {
      propertyType,
      floor,
      unit,
      area
    },
    filteredAddress,
    {
      block: filteredAddress.block,
      buildingName: filteredAddress.building_name,
      fullAddress: filteredAddress.address,
      id: filteredAddress.id,
      postal: filteredAddress.postal,
      street: filteredAddress.street
    },
    ValuationRes,
    'Condo'
  )

  const createAddressRes = await fetch('/api/addressDetails/create/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(addressPayload)
  }).then(data => data.json())

  if ('valuationPrice' in createAddressRes) {
    return true
  }
  return false
}
