
const useSendSlack = () => {
  return async (type: string, message: string) => {
    const payload = {
      messageType: type,
      messageBody: message
    }

    const slackParam = new URLSearchParams(payload).toString()

    await fetch(`/api/log/send-slack/?${slackParam}`)
  }
}

export default useSendSlack
