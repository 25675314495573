import { useAccountDetail } from 'common/hooks/useAccountDetail'
import { useAddressDetails } from 'common/hooks/useAddressDetails'
import { getQueryString } from 'common/utils/getQueryString'
import { logger } from 'common/utils/logger'
import { useSingpassStore } from 'modules/Singpass/stores/address.store'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useOnboardStore } from '../stores/onboard.store'
import { isAccountCreated } from '../utils/isAccountCreated'
import { useRefreshTokenMutation } from './useRefreshTokenMutation'

const useIndexFlow = () => {
  const { isFromSingpass, selectedAddress, setView } = useOnboardStore()
  const setAppRedirect = useSingpassStore(state => state.setAppRedirect)
  const refreshMutation = useRefreshTokenMutation()
  const { accountQuery } = useAccountDetail()
  const { addressQuery } = useAddressDetails()

  const router = useRouter()
  const { source } = router.query

  useEffect(() => {
    if (source === 'mobile') {
      setAppRedirect(true)
    }
  }, [source])

  useEffect(() => {
    if (accountQuery.isSuccess && addressQuery.isSuccess) {
      if (isFromSingpass && selectedAddress !== null) {
        setView('ADDRESS_DETAILS')
        logger.log(
          'User coming in from Singpass callback. Redirecting to onboard page.'
        )
        router.replace(`/onboard/${location.search}`)
        return
      }

      if (
        addressQuery.data?.addressDetails &&
        addressQuery.data?.addressDetails?.length > 0
      ) {
        refreshMutation.mutate()
      }
      if (
        accountQuery.data?.migrateSource &&
        !accountQuery.data?.migratedHasClaimedAddress &&
        addressQuery.data?.addressDetails
      ) {
        setView('PRE_PROVISION')
        logger.log(
          'User coming in from pre provisioned flow. Redirecting to onboard page.'
        )
        router.replace(`/onboard/${location.search}`)
        return
      }

      if (
        accountQuery.data?.isFirstTimeLogin === false ||
        (addressQuery.data?.addressDetails &&
          addressQuery.data?.addressDetails?.length > 0)
      ) {
        logger.log('Existing user login. Redirecting to household page.')
        if (source === 'mobile') {
          window.location.href = `omhv4://${window.location.hostname}/household`
        }
        router.replace(`/household/${location.search}`)
        return
      }

      const condoMaskFlow = async () => {
        const {
          postalCode,
          area,
          propertyType,
          floor,
          unit,
          ...restQuery
        } = router.query
        if (
          postalCode &&
          area &&
          floor &&
          unit &&
          propertyType === 'Condo' &&
          accountQuery.data?.isFirstTimeLogin === true
        ) {
          const isAccount = await isAccountCreated({
            area: area as string,
            floor: floor as string,
            postalCode: postalCode as string,
            unit: unit as string
          })
          if (isAccount) {
            const query = getQueryString(restQuery as Record<string, string>)
            router.replace(`/household/?${query}`)
            return isAccount
          } else {
            logger.error(
              '[Problem in mask Valuation flow] Values are as follows',
              {
                area,
                floor,
                postalCode,
                propertyType,
                unit
              }
            )
          }
        }
        return false
      }

      condoMaskFlow().then((value) => {
        if (!value) {
          router.replace(`/onboard/${location.search}`)
        }
      })
    }
  }, [accountQuery.isSuccess, addressQuery.isSuccess, isFromSingpass])
}

export default useIndexFlow
