import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { Account } from 'common/types/Account.type'
import { useQueryRouter } from './useQueryRouter'

const useAccountDetail = () => {
  const router = useQueryRouter()
  const accountQuery = useQuery<Account>({
    queryKey: ['account'],
    queryFn: () => axios.get('/api/account/get/').then((res) => res.data),
    onError: () => {
      router.push('/api/auth/logout')
    }
  })

  return {
    accountQuery
  }
}

export { useAccountDetail }
