import { ESAddress } from 'common/types/Address'
import { LANG } from 'config/lang'

import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

type ViewType = typeof LANG.SETUP.VIEW[keyof typeof LANG.SETUP.VIEW]
export type OnboardFilterValues = 'HDB' | 'Condo' | 'Others'

type OnboardStoreType = {
  view: ViewType | 'LOADING'
  setView: (view: ViewType) => void
  selectedAddress: ESAddress | null
  setSelectedAddress: (selectedAddress: ESAddress | null) => void
  isUpdateAddress: boolean
  setIsUpdateAddress: (value: boolean)=> void
  isFromSingpass: boolean
  setIsFromSingpass: (isFromSingpass: boolean) => void
  addressDetailId: string
  setAddressDetailId: (value:string)=>void
  filter: OnboardFilterValues| null
  setFilter: (value:OnboardFilterValues)=>void
}

const useOnboardStore = create<OnboardStoreType>()(
  devtools(
    (set) => ({
      isFromSingpass: false,
      isUpdateAddress: false,
      addressDetailId: '',
      setAddressDetailId: (addressDetailId) => set({ addressDetailId }),
      setIsUpdateAddress: (isUpdateAddress) => set({ isUpdateAddress }),
      view: 'LOADING',
      selectedAddress: null,
      setView: (view) => set({ view }),
      setSelectedAddress: (selectedAddress) => set({ selectedAddress }),
      setIsFromSingpass: (isFromSingpass) => set({ isFromSingpass }),
      filter: null,
      setFilter: (filter) => { set({ filter }) }
    })
  )
)

export { useOnboardStore }
