import Router from 'next/router'
import { useEffect, useState } from 'react'

export const usePageLoader = () => {
  const [loading, setLoading] = useState(false)
  const handleStart = (url:string) => {
    return (url !== Router.asPath) && setLoading(true)
  }
  const handleComplete = (url:string) => {
    return setLoading(false)
  }

  useEffect(() => {
    Router.events.on('routeChangeStart', handleStart)
    Router.events.on('routeChangeComplete', handleComplete)
    Router.events.on('routeChangeError', handleComplete)
    return () => {
      Router.events.off('routeChangeStart', handleStart)
      Router.events.off('routeChangeComplete', handleComplete)
      Router.events.off('routeChangeError', handleComplete)
    }
  }, [Router.events])

  return loading
}
