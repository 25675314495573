import { ESAddress } from 'common/types/Address'
import {
  SingpassAddress,
  SingpassSuccessResponse
} from 'common/types/Singpass.type'
import { ONBOARDING } from 'config/onboarding'
import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

type ViewType = typeof ONBOARDING.SINGPASS_VIEW[keyof typeof ONBOARDING.SINGPASS_VIEW]

type SingpassStoreType = {
  haveMultipleAddresses: Boolean
  fullAddress: ESAddress | null
  singpassResponse: SingpassSuccessResponse | null
  setFullAddress: (address: ESAddress | null) => void
  setSingpassAddress: (singpassAddress: SingpassAddress | null) => void
  setSingpassResponse: (response: SingpassSuccessResponse | null) => void
  setHaveMultipleAddresses: (haveMultipleAddresses: Boolean) => void
  singpassAddress: SingpassAddress | null
  isUpdate: Boolean
  setIsUpdate: (isUpdate: Boolean) => void
  addressDetailId: string
  setAddressDetailId: (addressDetailId: string) => void
  isOnboard: Boolean
  setIsOnboard: (isOnboard: Boolean) => void
  view: ViewType
  setView: (value: ViewType) => void
  appRedirect: boolean
  setAppRedirect: (view: boolean) => void
}

const useSingpassStore = create<SingpassStoreType>()(
  devtools(
    persist(
      (set) => ({
        appRedirect: false,
        setAppRedirect: (appRedirect) => { set({ appRedirect }) },
        isUpdate: false,
        view: 'CONFIRM_ADDRESS',
        haveMultipleAddresses: false,
        singpassAddress: null,
        singpassResponse: null,
        fullAddress: null,
        addressDetailId: '',
        isOnboard: true,
        setView: (view) => set({ view }),
        setSingpassResponse: (response: SingpassSuccessResponse | null) =>
          set({ singpassResponse: response }),
        setSingpassAddress: (singpassAddress: SingpassAddress | null) =>
          set({ singpassAddress }),
        setFullAddress: (fullAddress: ESAddress | null) => set({ fullAddress }),
        setHaveMultipleAddresses: (haveMultipleAddresses: Boolean) =>
          set({ haveMultipleAddresses }),
        setIsUpdate: (isUpdate: Boolean) => set({ isUpdate }),
        setAddressDetailId: (addressDetailId: string) =>
          set({ addressDetailId }),
        setIsOnboard: (isOnboard: Boolean) => set({ isOnboard })
      }),
      {
        name: 'singpass-address-storage'
      }
    )
  )
)

export { useSingpassStore }
