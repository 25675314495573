import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { AddressDetails } from 'common/types/AddressDetails'
import { useAddressDetailStore } from 'stores/addressDetails.store'
import { useQueryRouter } from './useQueryRouter'

const useAddressDetails = () => {
  const router = useQueryRouter()
  const setIsHdb = useAddressDetailStore(state => state.setIsHdb)
  const addressQuery = useQuery<AddressDetails>({
    queryKey: ['addressDetails'],
    queryFn: () => axios.get('/api/addressDetails/get/').then(res => res.data),
    suspense: true,
    onError: () => {
      router.push('/api/auth/logout')
    },
    onSuccess: (data) => {
      const detail = data.addressDetails[0]
      if (!detail) {
        setIsHdb(null)
        return
      }

      if (detail.propertyType === 'CONDOMINIUM' || detail.propertyType === 'APARTMENT' || detail.propertyType === 'EXECUTIVE CONDOMINIUM') {
        setIsHdb(false)
      } else {
        setIsHdb(true)
      }
    }
  })

  return {
    addressQuery
  }
}

export { useAddressDetails }
