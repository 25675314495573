import { Backdrop, Box, CircularProgress } from '@mui/material'
import { FC } from 'react'

interface PageLoaderProps{
  show:boolean
}
const PageLoader:FC<PageLoaderProps> = ({ show }) => {
  return show
    ? (
      <Box height='100vh'>
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          invisible
          open
        >
          <Box
            sx={{
              width: 72,
              height: 72,
              p: 2,
              bgcolor: 'rgba(0,0,0,0.05)',
              borderRadius: '50%'
            }}
          >

            <CircularProgress
              color='primary'
              thickness={8}
            />
          </Box>
        </Backdrop>
      </Box>
      )
    : null
}

export { PageLoader }
