
import { withPageAuthRequired } from '@auth0/nextjs-auth0/client'
import { Box } from '@mui/material'
import PageLoader from 'common/components/PageLoader'
import { useRudderStack } from 'common/hooks/useRudderstack'
import useIndexFlow from 'modules/Onboard/hooks/useIndexFlow'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

const Home = () => {
  const { pageEvent } = useRudderStack()
  const router = useRouter()
  useIndexFlow()

  useEffect(() => {
    pageEvent('Login Success', {
      ...router.query
    })
  }, [router])

  return (
    <Box
      flexGrow={1}
      overflow='auto'
    >
      <PageLoader show />
    </Box>
  )
}

export default withPageAuthRequired(Home)
