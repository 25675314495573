import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

interface AddressState {
  isHdb: boolean|null
  setIsHdb: (value: boolean|null)=>void
}

const useAddressDetailStore = create<AddressState>()(
  devtools(
    persist(
      (set) => ({
        isHdb: null,
        setIsHdb: (isHdb) => { set({ isHdb }) }
      }),
      {
        name: 'address-storage'
      }
    )
  )
)

export { useAddressDetailStore }
