import { ESAddress, ESAddressDetail } from 'common/types/Address'
import { Valuation } from 'common/types/Valuation.type'
import { formatDate } from 'common/utils/date'
import { Values } from '../config'

export const mapAddressDetails = (
  values: Values,
  searchedAddress: ESAddressDetail,
  selectedAddress: ESAddress,
  valuation: Valuation,
  type: 'HDB' | 'Condo' | null,
  noOfCoOwner: number = 0,
  purchaseDate: string = '',
  source: string = ''
) => {
  let payload = {
    propertyType: values.propertyType.toUpperCase(),
    floor: Number(values.floor),
    unit: values.unit,
    areaSqm: 0,
    areaSqft: 0,
    flatModel: values.propertyType.toUpperCase(),
    completionDate: null,
    address: {
      district: searchedAddress.district,
      top: searchedAddress.top < 0 ? '' : searchedAddress.top.toString(),
      tenure: searchedAddress.tenure,
      countryCode: 'SGP',
      townName: searchedAddress.town,
      region: searchedAddress.planningRegion,
      addressStreetName: selectedAddress.street,
      projectName: selectedAddress.buildingName,
      addressBuildingName: selectedAddress.buildingName,
      postalCode: selectedAddress.postal,
      modelType: searchedAddress.modelType,
      builtYear: searchedAddress.builtYear?.toString() || '',
      leaseCommenceYear: searchedAddress.leaseCommence?.toString() || '',
      block: selectedAddress.block,
      latitude: String(searchedAddress.latitude),
      longitude: String(searchedAddress.longitude),
      realLatitude: String(searchedAddress.latitude),
      realLongitude: String(searchedAddress.longitude)
    },
    purchasePrice: Number(valuation.valuation),
    valuationRequestedDate: formatDate(Date.now()),
    valuationPrice: Number(valuation.valuation),
    noOfCoOwner: 0,
    source,
    purchaseDate: '',
    expectedSellingDate: null
  }

  if (noOfCoOwner && noOfCoOwner !== 0) {
    payload = {
      ...payload,
      noOfCoOwner
    }
  }

  if (purchaseDate) {
    payload = {
      ...payload,
      purchaseDate
    }
  }
  if (type === 'HDB') {
    payload = {
      ...payload,
      areaSqm: Number(values.area)
    }
  } else {
    payload = {
      ...payload,
      areaSqft: Number(values.area)
    }
  }

  return payload
}
