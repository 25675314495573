import { Account } from 'common/types/Account.type'
import { Auth0User } from 'common/types/Auth0User.type'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

type UserStoreType = {
  auth0User: Auth0User | null
  account: Account | null
  setAuth0User: (user: Auth0User) => void
  setAccount: (account: Account) => void
  removeAuth0User: () => void
  removeAccount: () => void
  hasIdentified: boolean
  setHasIdentified: (value: boolean)=>void
}

const useUserStore = create<UserStoreType>()(
  devtools(
    (set) => ({
      auth0User: null,
      account: null,
      hasIdentified: false,
      setHasIdentified: (hasIdentified) => { set({ hasIdentified }) },
      setAuth0User: (auth0User: Auth0User) => set({ auth0User }),
      removeAuth0User: () => set({ auth0User: null }),
      setAccount: (account: Account) => set({ account }),
      removeAccount: () => set({ account: null })
    })
  )
)

export { useUserStore }
