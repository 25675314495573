import { useUser } from '@auth0/nextjs-auth0/client'
import { Account } from 'common/types/Account.type'
import { logger } from 'common/utils/logger'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { useRouter } from 'next/router'
import { useUserStore } from 'stores/user.store'
import useSendSlack from './useSendSlack'

const useRudderStack = () => {
  const hasIdentified = useUserStore(state => state.hasIdentified)
  const setHasIdentified = useUserStore(state => state.setHasIdentified)
  const LDClient = useLDClient()
  const user = useUser()
  const sendSlackMessage = useSendSlack()
  const router = useRouter()

  const identifyUser = async () => {
    try {
      const account: Account = await fetch('/api/account/get/').then((res) => res.json())
      if (account) {
        window.rudderanalytics.identify(account.uid)

        if (router.query.public_uid && typeof router.query.public_uid === 'string' && router.query.public_uid.length > 0) {
          window.rudderanalytics.setAnonymousId(router.query.public_uid)
          window.rudderanalytics.alias(account.uid, {
            type: 'alias',
            previousId: router.query.public_uid,
            userId: account.uid
          })
        }

        LDClient?.identify({
          key: account.uid
        })

        setHasIdentified(true)
      } else {
        await sendSlackMessage(
          'ACCOUNT ERROR - No account found in db',
          `userID:${user.user?.sub}`
        )
      }
    } catch {
      logger.error('error in identity function')
    }
  }

  return {
    pageEvent: async (pageName: string, additional?: object) => {
      if (!hasIdentified) { await identifyUser() }

      const userType = (user.user as any).omhroles &&
        (user.user as any).omhroles.includes('agent')
        ? { user_type: 'Agent' }
        : {}

      window.rudderanalytics.page(pageName, {
        ...(additional && additional),
        country: 'SGP',
        ...userType
      })
    },
    trackEvent: async (eventName: string, additional?: object) => {
      if (!hasIdentified) { await identifyUser() }

      const userType = (user.user as any).omhroles &&
        (user.user as any).omhroles.includes('agent')
        ? { user_type: 'Agent' }
        : {}

      window.rudderanalytics.track(
        eventName,
        {
          country: 'SGP',
          title: window.document.title,
          url: window.location.href,
          ...(additional && additional),
          ...userType
        }
      )
    }
  }
}

export { useRudderStack }
