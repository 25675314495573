import { format, isValid } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

const formatDate = (
  date: Date | number,
  formatValue = 'Y-MM-dd hh:mm:ss'
): string => {
  if (!isValid(date)) {
    return ''
  }
  const utcDate = utcToZonedTime(date, 'UTC')
  const formatDate = format(utcDate, formatValue)

  return formatDate ?? ''
}

export { formatDate }
