import { useRouter } from 'next/router'

export const useQueryRouter = () => {
  const router = useRouter()

  const push = (pathname: string | null | undefined) => {
    router.push({
      pathname,
      query: router.query
    })
  }
  return { push }
}
